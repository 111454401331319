<template>

<o-field variant="primary">
	<o-select size="small" v-model="offer" @change="change">
		<option v-for="(item, key) in offers" :value="key">
			{{ item }}
		</option>
	</o-select>
</o-field>

</template>

<script>
	export default {
		name: 'AnalyticsOffers',
		data() {
			return {
				offer: '',
				offers: {
				},
			}
		},
		emits: ['change'],
		methods: {
			loadData(e) {
				let params = {}
				if(e && 'branch_id' in e) params.branch_id = e.branch_id
				this.$root.loadData(params, this, (dt) => {
					this.offers = {}
					Object.assign(this.offers, dt)
					this.offer = Object.values(this.offers)[0]
					this.change()
				})
			},
			change() {
				this.$emit('change', {'offer':this.offer})
			},
		},
		mounted() {
			this.loadData()
		},
	}
</script>
